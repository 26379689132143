import React, { useState } from "react";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { Box, css } from "@mui/system";
import { useTranslation } from "react-i18next";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";
import { Backdrop, ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import { SectionTitle2 } from "StaticPages/Components/Commons/SectionTitle2";
import { SectionText1 } from "StaticPages/Components/Commons/SectionText1";
import { TransitionImage } from "StaticPages/Components/TransitionImage";
import { FadeAndSlideAnimation } from "StaticPages/Components/Animations/FadeAndSlideAnimation";
import { OpenInNew } from "@mui/icons-material";
import { Link } from "gatsby";

export default () => {
    const { t } = useTranslation();
    return <>
        <Helmet title={t("ミラーワールド | VISUALIZ INC")} />
        <Layout>
            <div>
                <Main />
            </div>
        </Layout>
    </>;
};

const Main = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return <>
        <main>
            <PageSummary
                title={t("メタバース")}
                description={<>
                    {t("ミラーワールドの概念はメタバースというキーワードへと変化しました。")}<br />
                    {t("どちらにしろ目指す先にあるのは物理世界とデジタル世界の高次元での融合。")}<br />
                    {t("「街」そのものを作り出すプラットフォームです。")}
                    {t("概要を是非ご覧ください。")}
                </>}
                subTitle={t("META VERSE")}
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                src={require("../../../static/images/mirrorworld_top.jpg").default}
            />

            <Box
                px={{ xs: 1, sm: 2 }}
                py={{ xs: 4, sm: 6 }}
                sx={{
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Typography variant="h6" component="h3">
                    {t("2021年10月27日に開催された「Tokyo Venture Conference Frontier」に弊社代表高尾が登壇致しました。")}
                </Typography>

                <Box mx="auto">
                    <Box
                        p={1}
                        mt={3}
                    >
                        <Link
                            target="_blank"
                            css={css({
                                display: "flex",
                                color: theme.palette.text.primary,
                                alignItems: "center",
                            })}
                            to="https://prtimes.jp/main/html/rd/p/000000009.000081023.html"
                        >
                            <>
                                <OpenInNew fontSize="small" />
                                <Typography
                                    textAlign="left"
                                    sx={{ marginLeft: "1rem" }}
                                >{t("経営者や仮想空間”メタバース”をビジネス活用したい方必見！10/27メタバースの開拓者4名をお招きし無料オンラインイベントを開催")}</Typography>
                            </>
                        </Link>
                    </Box>
                    <Box
                        p={1}
                        mx="auto"
                    >
                        <Link
                            target="_blank"
                            css={css({
                                display: "flex",
                                color: theme.palette.text.primary,
                                alignItems: "center"
                            })}
                            to="https://prtimes.jp/main/html/rd/p/000000010.000081023.html"
                        >
                            <>
                                <OpenInNew fontSize="small" />
                                <Typography
                                    textAlign="left"
                                    sx={{ marginLeft: "1rem" }}
                                >{t("10/27メタバースの開拓者4名をお招きし無料オンラインイベントを開催！メタバース最新事情を徹底解剖！現実と仮想の融合の先にある未来とは")}</Typography>
                            </>
                        </Link>
                    </Box>
                    <Box
                        p={1}
                        mx="auto"
                    >
                        <Link
                            target="_blank"
                            css={css({
                                display: "flex",
                                color: theme.palette.text.primary,
                                alignItems: "center"
                            })}
                            to="https://tokyovcon.com/"
                        >
                            <>
                                <OpenInNew fontSize="small" />
                                <Typography
                                    textAlign="left"
                                    sx={{ marginLeft: "1rem" }}
                                >{t("Tokyo Venture Conference サイト")}</Typography>
                            </>
                        </Link>
                    </Box>
                </Box>
            </Box>

            <Box
                mt={{ xs: 3, sm: 4, md: 6 }}
                px={{ xs: 1, sm: 2 }}
                py={{ xs: 4, sm: 6 }}
                bgcolor={theme.palette.background.default}
            >
                <Grid
                    container
                    sx={{
                        maxWidth: "1080px",
                        margin: "0px auto"
                    }}
                >
                    <Grid item xs={12} sm={4}>
                        <Box p={{ xs: 1, sm: 2 }}>
                            <PreviewableImage
                                src="/images/image2_1.12.jpg"
                                largeSrc={"/images/image2_1.1.2.jpg"}
                                alt="SAMPLE1"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box p={{ xs: 1, sm: 2 }}>
                            <PreviewableImage
                                src="/images/image3_1.12.jpg"
                                largeSrc={"/images/image3_1.1.3.jpg"}
                                alt="SAMPLE2"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box p={{ xs: 1, sm: 2 }}>
                            <PreviewableImage
                                src="/images/image1_1.12.jpg"
                                largeSrc={"/images/image1_1.1.1.jpg"}
                                alt="SAMPLE3"
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box
                mt={{
                    xs: 4,
                    md: 6
                }}
            >
                <iframe
                    style={{ border: "none", width: "100%", maxWidth: "620px", height: "400px", }}
                    src="https://www.youtube.com/embed/ZS9UK_crCUc?autoplay=1&mute=1&rel=0&modestbranding=1"
                >
                </iframe>
            </Box>

            <Box
                mt={{ xs: 3, sm: 4, md: 6 }}
                px={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("ミラーワールド或いはデジタルツイン、\nそしてメタバース")}
                />
                <SectionText1>
                    {t("ここ数年のミラーワールドを取り巻く環境は、半ば啓蒙的な要素も加わった事もあり活気を通り越し、或いは喧騒とでも呼べるような状況に思える。")}
                    {t("シンガポールの都市OSから始まり、巨大デジタルツインを成し遂げた上海、観光への特化を目指したヘルシンキなど、新しい技術や概念を巡る競争は側から見ると未来的な示唆に富み、見るものに参加の意識をより際立たせる。")}
                    {t("このデジタルをめぐる喧騒は確かVRの時もそうだった。古くはWebの技術が確立された時も。")}
                    {t("VRとWebの大きな違いと言えば、デバイスとプラットフォームと言えるだろう。")}
                    {t("必然的にこの両者には独占出来る市場かどうか、と言った色でも分けられるのではないだろうか。")}
                </SectionText1>

                <SectionText1>
                    {t("もちろんVRを独占する事は難しい。難しいのだが、社会的プラットフォームを独占出来るパワーは今やどこを探しても見つける事は難しいだろう。")}
                    {t("そしてミラーワールドだ。")}
                    {t("この新しい言葉はデジタルツインやメタバースと言った様々な造語で語られている。")}
                    {t("もちろん統一された名称も大事なのだが、それよりも大事な事がある。")}
                    {t("このミラーワールドはインターネットを超える潜在的パワーを秘めている（らしい）とも語られているツールを一社独占のデバイス的ツールに決してしてはならないと言う事だ。")}
                </SectionText1>
                <SectionText1>
                    {t("しかしここで一つの疑問が湧いてくる。果たしてデジタルの中にしか存在しないデータを「ミラー」と呼べるのか。それはユニバースの拡張として本当に正しいのか。")}
                    {t("僕が求めるミラーワールドとは現実のミラーである事が大前提だ。")}
                    {t("そして僕が住んでいるこの世界は、幸運な事に世界を支配する全能の神や専制君主も存在していない。")}
                    {t("とするならば、ミラーワールドはUniの集合体であるべきだ。")}
                    {t("一社ではなく、沢山の会社が成功し、様々な人々がこの場所で出会い、勤務し、成功し、エンターテイメントを楽しむ。")}
                </SectionText1>
                <SectionText1>
                    {t("現実と高い次元で融合し、これまでにない世界を実現させなければいけない。")}
                    {t("心配しなくでも大丈夫。僕がやらなくても必ず誰かが実現させる。")}
                    {t("その時には出来るだけインターネット空間のように自由で闊達な、そして現実社会のように法治を実現させるだろう。")}
                    {t("言われなき他者への攻撃や他者の財産を奪う事はあってはならない。")}
                    {t("やがてミラーは合わせ鏡のように奥行きを増し、新しい大きな市場を形成するだろう。技術的な難関はあるだろう、が、僕の知っている限りもう殆ど実現可能な技術は出揃っている。")}
                </SectionText1>

                <SectionText1>
                    {t("出来るなら現在の日本の生産性を低下させている原因の一つでもあるDXへの対応を加速して欲しいという願いも込め2025年の崖が来るまでに実現させたいとも思う。")}
                    {t("その為にVISUALIZは幾つかのプロジェクトをスタートさせてもいる。")}
                    {t("旅は始まったばかりだ。そして仲間は多分まだ少ない。")}
                </SectionText1>
            </Box>

            <Box
                py={{ xs: 3, sm: 4, md: 6 }}
            />
        </main>
    </>;
};

const PreviewableImage = ({
    alt,
    src,
    largeSrc,
}: {
    src: string;
    largeSrc: string;
    alt: string;
}) => {
    const [open, setOpen] = useState(false);

    return <>
        <Backdrop
            open={open}
            onClick={_ => setOpen(false)}
            sx={{
                zIndex: 2
            }}
        >
            <FadeAndSlideAnimation
                in={open}
                transform={{
                    rotate: "0",
                    translate: { x: "0px", y: "0px" },
                    scale: 0.92
                }}
                css={css`
                    max-width:90%;
                    max-height: 90%;
                `}
                delay={{
                    appear: 800,
                }}
            >
                <img
                    src={largeSrc}
                    alt={alt}
                    css={css`
                    max-width:100%;
                    max-height: 100%;
            `}
                />
            </FadeAndSlideAnimation>
        </Backdrop>
        <ButtonBase
            css={onHoverStyle}
            onClick={() => setOpen(true)}
        >
            <TransitionImage
                alt={alt}
                src={src}
            ></TransitionImage>
        </ButtonBase>
    </>;
};

const onHoverStyle = css`
    overflow: hidden;

    & > * {
           transform: scale(1);
           transition: all 0.8s;
    }

    &:hover{
        & > * {
           transform: scale(1.1);
        }
    }
`;
